import Marquee from 'react-fast-marquee';
import './App.css';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles


function App() {
  useEffect(() => {
    AOS.init()
  }, []);

  function scrollToBottom() {
    const documentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
    const destination = documentHeight - window.innerHeight;

    if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({
            top: destination,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo(0, destination);
    }
  }


  return (
    <div className="App">
      <div className='nav'>
        <img src={process.env.PUBLIC_URL+'/logo.png'}/>
      </div>
      <div className='hero'>
        <img src={process.env.PUBLIC_URL+'/hero.png'}/>
        <div className='hero-cta'>
          <h1>Don't let those old machines rot in your yard</h1>
          <p>Call us today and get an offer on any of your old equipment.  From compact riders to large frame skid steers, if you got it, we want it.</p>
          <button onClick={() => scrollToBottom()}>Call Now</button>
        </div>
      </div>
      <div className='about'>
        <div className='half'>
          <img data-aos='fade-up' id='cash' src={process.env.PUBLIC_URL+'/money.png'}/>
        </div>
        <div className='half'>
          <div className='text-block' data-aos='fade-up' data-aos-delay='200'>
            <h2>WHAT WE DO</h2>
            <p>We specialize in acquiring your surplus industrial equipment. With a commitment to seamless transactions and top-dollar offers, we streamline the process of selling used machinery. Whether you're looking to upgrade your equipment or simply clear out surplus assets, trust Lifted Surplus to provide a swift and confidential solution. Maximize the value of your used industrial assets with us – your trusted partner in equipment acquisition.</p>
          </div>
          <div className='text-block' data-aos='fade-up' data-aos-delay='400'>
            <h2>HOW DOES IT WORK?</h2>
            <p>Begin by filling out our simple online form, providing key details about what you're looking to sell. Once submitted, our expert team will promptly assess the information and generate a competitive offer tailored to your equipment. Following your acceptance, we coordinate a convenient pickup schedule, handling all logistics and ensuring a hassle-free experience. Upon inspection, we finalize the transaction swiftly, ensuring you receive prompt payment.</p>
          </div>
        </div>
      </div>
      <div className='contact'>
        <div className='contact-main'>
          <div className='form' data-aos='fade-up'>
            <h2>GET AN OFFER</h2>
            <form action='https://formsubmit.co/ad4b54cd19d5847cf567a68fca402cff' method='POST'>
              <input type='text' placeholder='Your Name' name='name'></input>
              <input type='text' placeholder='Company Name' name='company'></input>
              <div class='form-side'>
                <input type='text' placeholder='Phone Number' name='phone' id='phone'></input>
                <input type='text' placeholder='Ext' name='extension' id='ext'></input>
              </div>
              <input type='text' placeholder='Email Address' name='email'></input>
              <input type='text' placeholder='What are you selling? (e.g. CAT C5000)' name='item'></input>
              <div class='form-side'>
                <p>By submitting this form you agree to share your information with Company Name and consent to being contacted by one of our representatives</p>
                <input type='submit' name='submit' value='SUBMIT'/>
              </div>
            </form>
          </div>

          <div className='contact-info'>
            <div data-aos='fade-up' data-aos-delay='200'>
            <h2>EMAIL</h2>
            <p>quote@liftedsurplus.com</p>
            </div>
            <div data-aos='fade-up' data-aos-delay='400'>
            <h2>PHONE</h2>
            <p>(832) 350 8426</p>
            </div>
          </div>
        </div>
        <Marquee id='marquee' autoFill direction='right'>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animforklift.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animskidsteer.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animtelehandler.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animskidsteer.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animtelehandler.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animforklift.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animskidsteer.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animforklift.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animtelehandler.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animforklift.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animtelehandler.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animskidsteer.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animforklift.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animtelehandler.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animskidsteer.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animtelehandler.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animforklift.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animskidsteer.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animforklift.gif'} className='actor'/>
          <img alt='vehicle' src={process.env.PUBLIC_URL+'/animtelehandler.gif'} className='actor'/>

        </Marquee>
      </div>
    </div>
  );
}

export default App;
